import fetch from 'cross-fetch';

export const REQUEST_FEATURED = 'REQUEST_FEATURED';
export const RECEIVE_FEATURED = 'RECEIVE_FEATURED';
export const REQUEST_EVENTS = 'REQUEST_EVENTS';
export const RECEIVE_EVENTS = 'RECEIVE_EVENTS';
export const REQUEST_EVENT_DETAILS = 'REQUEST_EVENT_DETAILS';
export const RECEIVE_EVENT_DETAILS = 'RECEIVE_EVENT_DETAILS';
export const CLEAR_EVENT_DETAILS = 'CLEAR_EVENT_DETAILS';

const apiRoot = process.env.POPMATH_API_ROOT;

function requestFeatured() {
  return {
    type: REQUEST_FEATURED,
  };
}

function receiveFeatured(json) {
  return {
    type: RECEIVE_FEATURED,
    featured: json.featured,
  };
}

function requestEvents() {
  return {
    type: REQUEST_EVENTS,
  };
}

function receiveEvents(json) {
  return {
    type: RECEIVE_EVENTS,
    events: json.events,
  };
}

function requestEventDetails(id) {
  return {
    type: REQUEST_EVENT_DETAILS,
    id,
  };
}

function receiveEventDetails(json) {
  return {
    type: RECEIVE_EVENT_DETAILS,
    event: json,
  };
}

function clearEventDetails() {
  return {
    type: CLEAR_EVENT_DETAILS,
  };
}

function fetchEvents(year = null) {
  const qs = year ? `?year=${year}` : '';
  return (dispatch) => {
    dispatch(requestEvents());
    return fetch(`${apiRoot}/events${qs}`)
      .then(response => response.json())
      .then(json => dispatch(receiveEvents(json)));
  };
}

function shouldFetchEvents(state) {
  return !state.events.isFetching;
}

export function fetchEventsIfNeeded(year = null) {
  return (dispatch, getState) => {
    if (shouldFetchEvents(getState())) {
      return dispatch(fetchEvents(year));
    }
  };
}

export function fetchEvent(id) {
  return (dispatch) => {
    dispatch(requestEventDetails(id));
    return fetch(`${apiRoot}/event/${id}`)
      .then(response => response.json())
      .then(json => dispatch(receiveEventDetails(json)));
  };
}

export function fetchFeaturedEvents(lat = null, lon = null) {
  const args = lat !== null && lon !== null ? `lat=${lat}&lon=${lon}` : '';
  return (dispatch) => {
    dispatch(requestFeatured());
    return fetch(`${apiRoot}/featured?${args}`)
      .then(response => response.json())
      .then(json => dispatch(receiveFeatured(json)));
  };
}
