/* globals google */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
} from 'react-google-maps';
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';
import { destVincenty } from '../helpers/dest-vicenty';
import EventMarker from './event-marker';
import GeolocationButton from './geolocation-button';
import { EventBasicProperties, eventPath } from '../helpers/events';

// Documentation at
// https://tomchentw.github.io/react-google-maps/

class MapFilter extends React.Component {
  constructor(props) {
    super(props);

    this.handleMapRef = this.handleMapRef.bind(this);
    this.handleBoundsChanged = this.handleBoundsChanged.bind(this);
    this.signalBoundsChanged = this.signalBoundsChanged.bind(this);
    this.handleMarkerClicked = this.handleMarkerClicked.bind(this);
    this.handleSeeAllButton = this.handleSeeAllButton.bind(this);
    this.handleGeolocation = this.handleGeolocation.bind(this);

    this.mapElement = null;
    this.throttledBoundsChangedTimer = null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { events } = this.props;

    return events.length !== nextProps.events.length;
  }

  componentWillUnmount() {
    clearTimeout(this.throttledBoundsChangedTimer);
    this.throttledBoundsChangedTimer = null;
    this.mapElement = null;
  }

  setDefaultBounds() {
    if (this.mapElement !== null) {
      this.mapElement.fitBounds(
        new google.maps.LatLngBounds(
          new google.maps.LatLng(MapFilter.DEFAULT_BOUNDS.sw.lat, MapFilter.DEFAULT_BOUNDS.sw.lon),
          new google.maps.LatLng(MapFilter.DEFAULT_BOUNDS.ne.lat, MapFilter.DEFAULT_BOUNDS.ne.lon)
        ),
        -1
      );
    }
  }

  setLocalBounds(lat, lon) {
    if (this.mapElement !== null) {
      console.log(`${lat}, ${lon}`);
      const sw = destVincenty(lat, lon, 225, MapFilter.LOCAL_RADIUS);
      const ne = destVincenty(lat, lon, 45, MapFilter.LOCAL_RADIUS);

      console.log(`${sw.lat}, ${sw.lon}`);
      console.log(`${ne.lat}, ${ne.lon}`);

      this.mapElement.fitBounds(
        new google.maps.LatLngBounds(
          new google.maps.LatLng(sw.lat, sw.lon),
          new google.maps.LatLng(ne.lat, ne.lon)
        ),
        -1
      );
    }
  }

  handleBoundsChanged() {
    if (this.throttledBoundsChangedTimer !== null) {
      clearTimeout(this.throttledBoundsChangedTimer);
    }
    this.throttledBoundsChangedTimer = setTimeout(this.signalBoundsChanged, 200);
  }

  handleMarkerClicked(eventID, eventURL) {
    const { history } = this.props;
    history.push(eventURL);
  }

  handleSeeAllButton() {
    this.setDefaultBounds();
  }

  handleGeolocation(latitude, longitude) {
    this.setLocalBounds(latitude, longitude);
  }

  signalBoundsChanged() {
    if (this.mapElement !== null) {
      const { onBoundsChanged } = this.props;
      const newBounds = this.mapElement.getBounds();
      onBoundsChanged(
        newBounds.getSouthWest().lat(),
        newBounds.getNorthEast().lat(),
        newBounds.getSouthWest().lng(),
        newBounds.getNorthEast().lng()
      );
    }
  }

  handleMapRef(ref) {
    this.mapElement = ref;
    this.setDefaultBounds();
  }

  render() {
    const key = process.env.GOOGLE_MAPS_API_KEY;
    const { events } = this.props;

    const MapComponent = withScriptjs(withGoogleMap(() => (
      <GoogleMap
        options={{
          streetViewControl: false,
          mapTypeControl: false,
        }}
        ref={this.handleMapRef}
        onBoundsChanged={this.handleBoundsChanged}
      >
        <MarkerClusterer
          averageCenter
          enableRetinaIcons
          gridSize={40}
          styles={[
            {
              textColor: 'black', height: 53, url: '/assets/img/clusters/m1.png', width: 53,
            },
            {
              textColor: 'black', height: 56, url: '/assets/img/clusters/m2.png', width: 56,
            },
            {
              textColor: 'black', height: 66, url: '/assets/img/clusters/m3.png', width: 66,
            },
            {
              textColor: 'black', height: 78, url: '/assets/img/clusters/m4.png', width: 78,
            },
            {
              textColor: 'black', height: 90, url: '/assets/img/clusters/m5.png', width: 90,
            },
          ]}
        >
          {events.map(event => (
            <EventMarker
              key={event.id}
              position={{ lat: Number(event.lat), lng: Number(event.lon) }}
              onClick={this.handleMarkerClicked}
              title={event.title}
              eventID={event.id}
              eventURL={eventPath(event)}
            />
          ))}
        </MarkerClusterer>
      </GoogleMap>
    )));

    return (
      <div className="google-map-filter">
        <MapComponent
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${key}&v=3.exp`}
          loadingElement={<div style={{ height: '100%' }} />}
          containerElement={<div className="google-map-filter-container" />}
          mapElement={<div style={{ height: '100%' }} />}
        />
        <button type="button" className="btn btn-default btn-map-filter" onClick={this.handleSeeAllButton}>
          <i className="icon fas fa-globe-europe" />
          { ' ' }
          See all events
        </button>
        <GeolocationButton className="btn btn-default  btn-map-filter" onGeolocation={this.handleGeolocation}>
          <i className="icon fas fa-search-location" />
          { ' ' }
          See events near me
        </GeolocationButton>
      </div>
    );
  }
}

MapFilter.KM_NEAR_ME = 30;
MapFilter.LOCAL_RADIUS = Math.sqrt(MapFilter.KM_NEAR_ME * MapFilter.KM_NEAR_ME * 2) * 1000;
MapFilter.DEFAULT_BOUNDS = {
  ne: {
    lat: 67.08252417180762, lon: 77.88061025000002,
  },
  sw: {
    lat: 20.669036455012066, lon: -16.689702249999982,
  },
};

MapFilter.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape(EventBasicProperties)).isRequired,
  onBoundsChanged: PropTypes.func,
};

MapFilter.defaultProps = {
  onBoundsChanged: () => {},
};

export default withRouter(MapFilter);
