import React, { useEffect } from 'react';
import SubmissionForm from './submission-form';
import Helmet from 'react-helmet';

export default function SubmissionPage() {

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <Helmet>
            <title>Submit an event | Pop Math</title>
            <meta property="og:title" content="Submit an event" />
            <meta name="description" content="We are happy to add your event to our calendar. Read our guidelines, submit and we'll help people find your event." />
            <meta property="og:description" content="We are happy to add your event to our calendar. Read our guidelines, submit and we'll help people find your event." />
          </Helmet>
          <h1>Submit an event</h1>
          <p className="lead mb-0">
            We're happy to add your event to our calendar.
          </p>
          <p className="lead mb-0">
            Please <strong>provide as much information as
            possible</strong> and <strong>read our guidelines</strong> to
            make sure it gets approved.
          </p>
          <p className="lead">
            If your event is accepted it might take a few days to appear on our site.
          </p>
        </div>
      </div>
      <div className="row justify-content-between">
        <div className="col-lg-4 order-lg-last">
          <div className="panel p-3">
            <h3>Submission guidelines</h3>
            <h4 className="mt-4">Eligible events</h4>
            <p>We accept both:</p>
            <ul>
              <li>math outreach events for a general audience;</li>
              <li>events about math outreach (academic or professional);</li>
            </ul>
            <p>they can be commercial or non-profit.</p>
            <p>
              The events must have a limited duration. Permanent installations (museums, etc.)
              are not eligible.
            </p>
            <h4 className="mt-4">Venue</h4>
            <p>
              <strong>Online events :</strong>
              {' '}
              Indicate the country and city of the organizing institution, or the main
              audience it's targeting.
            </p>
            <p>
              <strong>Events with several venues (in the same city):</strong>
              {' '}
              Indicate the address of the main venue.
            </p>
            <p>
              <strong>Events spanning several cities:</strong>
              {' '}
              Consider submitting separate events for each city, so local people can find
              it in the map.
            </p>
            <h4 className="mt-4">Large events</h4>
            <p>
              If your event takes place over
              {' '}
              <strong>many days</strong>
              {' '}
              or includes many
              {' '}
              <strong>separate activities</strong>
              , submit it as one single event (don't split it into several submissions).
            </p>
          </div>
        </div>
        <div className="col-xl-7 col-lg-8 order-lg-first">
          <SubmissionForm />
        </div>
      </div>
    </div>
  );
}
