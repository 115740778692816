import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withConfig, ConfigType } from '../containers/config';
import { monthNames, parseISODate } from '../helpers/date-formatter';
import { EventBasicProperties, eventPath } from '../helpers/events';

function EventBigTeaser({ config, event, onClick }) {
  const date = parseISODate(event.start);

  let location = [event.location, config.countries[event.country] || event.country]
    .filter(s => s).join(', ');
  if (event.isOnline) {
    location = `ONLINE / ${location}`;
  }

  return (
    <Link
      className="event-big-teaser"
      to={{ pathname: eventPath(event), state: { throughLink: true } }}
      onClick={onClick}
    >
      <div className="teaser-pre-title">
        <div className="teaser-date">
          <span className="teaser-date-month">{monthNames[date.month - 1]}</span>
          <span className="teaser-date-day">{date.day}</span>
        </div>
        <div className="teaser-location">{location}</div>
      </div>
      <div className="teaser-title">{event.title}</div>
      <div className="teaser-type">
        {config.types[event.type] || config.types[3]}
        {event.cancelled && ' '}
        {event.cancelled && <span className="badge badge-danger">Cancelled</span>}
      </div>
    </Link>
  );
}

EventBigTeaser.propTypes = {
  event: PropTypes.shape(EventBasicProperties).isRequired,
  onClick: PropTypes.func,
  config: ConfigType.isRequired,
};

EventBigTeaser.defaultProps = {
  onClick: () => {},
};

export default withConfig(EventBigTeaser);
