import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import { useField, useFormikContext } from 'formik';
import classNames from 'classnames';
import DatePicker from 'react-datepicker';
import HCaptcha from '@hcaptcha/react-hcaptcha';

export const PMTextInput = (props) => {
  const { label, id, name, description, pmRequired } = props;
  const htmlProps = Object.assign({}, props);
  delete htmlProps.pmRequired;
  const [field, meta] = useField(props);
  return (
    <div className={classNames('form-group', { 'form-required': pmRequired })}>
      <label htmlFor={id || name}>{label}</label>
      <input className={classNames('form-control', {'border-danger': meta.touched && meta.error })} type="text" {...field} {...htmlProps} />
      {meta.touched && meta.error ? (
        <div className="form-text text-danger">{meta.error}</div>
      ) : null}
      { description && (<div className="form-text text-muted form-item-description" dangerouslySetInnerHTML={{ __html: description }} />)}
    </div>
  );
};

export const PMTextarea = (props) => {
  const { label, id, name, description, pmRequired } = props;
  const htmlProps = Object.assign({}, props);
  delete htmlProps.pmRequired;
  const [field, meta] = useField(props);
  return (
    <div className={classNames('form-group', { 'form-required': pmRequired })}>
      <label htmlFor={id || name}>{label}</label>
      <textarea className={classNames('form-control', {'border-danger': meta.touched && meta.error})} {...field} {...htmlProps} />
      {meta.touched && meta.error ? (
        <div className="form-text text-danger">{meta.error}</div>
      ) : null}
      { description && (<div className="form-text text-muted form-item-description" dangerouslySetInnerHTML={{ __html: description }} />)}
    </div>
  );
};

export const PMCheckbox = (props) => {
  const { children, label, description } = props;
  // We need to tell useField what type of input this is
  // since React treats radios and checkboxes differently
  // than inputs/select/textarea.
  const [field, meta] = useField(Object.assign({}, props, { type: 'checkbox' }));
  return (
    <div className="form-check mb-2">
      <label className="form-check-label">
        <input className="form-check-input" type="checkbox" {...field} {...props} />
        { label }
        {children}
      </label>
      { description && (<div className="form-text text-muted form-item-description" dangerouslySetInnerHTML={{ __html: description }} />)}
      {meta.touched && meta.error ? (
        <div className="form-text text-danger">{meta.error}</div>
      ) : null}
    </div>
  );
};

export const PMSelect = (props) => {
  const { label, id, name, description, pmRequired } = props;
  const htmlProps = Object.assign({}, props);
  delete htmlProps.pmRequired;
  const [field, meta] = useField(props);
  return (
    <div className={classNames('form-group', { 'form-required': pmRequired })}>
      <label htmlFor={id || name}>{label}</label>
      <select className={classNames('form-control', {'border-danger': meta.touched && meta.error})} {...field} {...htmlProps} />
      {meta.touched && meta.error ? (
        <div className="form-text text-danger">{meta.error}</div>
      ) : null}
      { description && (<div className="form-text text-muted form-item-description" dangerouslySetInnerHTML={{ __html: description }} />)}
    </div>
  );
};

export const PMDatePicker = (props) => {
  const { label, id, name, description, pmRequired } = props;
  const htmlProps = Object.assign({}, props);
  delete htmlProps.pmRequired;
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);

  return (
    <div className={classNames('form-group', { 'form-required': pmRequired })}>
      <label htmlFor={id || name}>{label}</label>
      <DatePicker
        {...field}
        {...htmlProps}
        selected={(field.value && new Date(field.value)) || null}
        onChange={(val) => {
          setFieldValue(field.name, val);
        }}
        className={classNames('form-control', { 'border-danger': meta.touched && meta.error })}
        dateFormat="yyyy-MM-dd"
      />
      {meta.touched && meta.error ? (
        <div className="form-text text-danger">{meta.error}</div>
      ) : null}
      { description && (<div className="form-text text-muted form-item-description" dangerouslySetInnerHTML={{ __html: description }} />)}
    </div>
  );
};

export const PMHCaptcha = forwardRef((props, ref) => {
  const { setFieldValue, setFieldError, submitCount } = useFormikContext();
  const [field, meta] = useField(props);
  const captchaRef = useRef(null);
  useImperativeHandle(ref, () => captchaRef);

  const handleVerificationSuccess = (token) => {
    setFieldValue(field.name, token);
  };

  const handleError = () => {
    setFieldError(field.name, 'You failed the captcha challenge');
  };

  const handleExpiration = () => {
    setFieldError(field.name, 'The captcha challenge expired');
  };

  return (
    <div className="captcha">
      <HCaptcha
        {...props}
        onVerify={token => handleVerificationSuccess(token)}
        onError={handleError}
        onExpire={handleExpiration}
        ref={captchaRef}
      />
      {submitCount >= 1 && meta.error ? (
        <div className="form-text text-danger">{meta.error}</div>
      ) : null}
    </div>
  );
});
