import React from 'react';
import PropTypes from 'prop-types';
import EventList from './event-list';
import { EventBasicProperties } from '../helpers/events';

export default function SegmentedEventList({ events }) {
  const segments = [];
  let currentSegment = null;
  events.forEach((event) => {
    if (currentSegment === null
      || (currentSegment.max !== null && event.daysToGo > currentSegment.max)) {
      currentSegment = Object.assign({ events: [] }, SegmentedEventList.rangeFor(event.daysToGo));
      segments.push(currentSegment);
    }
    // Add the event
    currentSegment.events.push(event);
  });

  const sortedEvents = (segment) => {
    if (segment.sort !== undefined) {
      return segment.events.sort((a, b) => {
        if (a[segment.sort] < b[segment.sort]) {
          return -1;
        }
        if (a[segment.sort] > b[segment.sort]) {
          return 1;
        }
        return 0;
      });
    }
    return segment.events;
  };

  return (
    <div className="">
      { segments.map(eachSegment => ([
        (eachSegment.title !== null ? (
          <div
            key={`segment-${eachSegment.max || 'last'}-title`}
            className="event-list-segment-title"
          >
            {eachSegment.title}
          </div>
        ) : null),
        <EventList
          key={`segment-${eachSegment.max || 'last'}-list`}
          events={sortedEvents(eachSegment)}
        />,
      ])) }
    </div>
  );
}

SegmentedEventList.rangeFor = function rangeFor(daysAway) {
  return SegmentedEventList.ranges.find(range => range.max === null || daysAway <= range.max);
};

SegmentedEventList.ranges = [
  { max: 0, title: 'Ongoing', sort: 'end' },
  { max: 7, title: 'Next 7 days' },
  { max: 30, title: 'More than 7 days away' },
  { max: 90, title: 'More than 30 days away' },
  { max: null, title: 'More than 90 days away' },
];

SegmentedEventList.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape(EventBasicProperties)),
};

SegmentedEventList.defaultProps = {
  events: [],
};
