import React from 'react';
import PropTypes from 'prop-types';
import SegmentedEventList from './segmented-event-list';
import MapFilter from './filter-map';
import { EventBasicProperties } from '../helpers/events';
import EventList from './event-list';

export default class FilteredEventList extends React.Component {
  constructor(props) {
    super(props);

    this.handleMapBoundsChange = this.handleMapBoundsChange.bind(this);
    this.onRef = this.onRef.bind(this);
    this.onStickyTopRef = this.onStickyTopRef.bind(this);
    this.onStickyBottomRef = this.onStickyBottomRef.bind(this);
    this.onMapContainerRef = this.onMapContainerRef.bind(this);
    this.$element = null;
    this.$mapContainerElement = null;
    this.$stickyTopElement = null;
    this.$stickyBottomElement = null;
    this.state = {
      minLat: MapFilter.DEFAULT_BOUNDS.sw.lat,
      maxLat: MapFilter.DEFAULT_BOUNDS.ne.lat,
      minLon: MapFilter.DEFAULT_BOUNDS.sw.lon,
      maxLon: MapFilter.DEFAULT_BOUNDS.ne.lon,
    };
  }

  componentDidMount() {
    this.setupWaypoints();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Skip scrolling for the initial update
    if (window.scrollY > this.$element.offset().top) {
      window.scrollTo({ top: this.$element.offset().top });
    }

    if (window.Waypoint !== undefined) {
      window.Waypoint.refreshAll();
    }
  }

  onRef(element) {
    this.$element = $(element);
  }

  onMapContainerRef(element) {
    this.$mapContainerElement = $(element);
  }

  onStickyTopRef(element) {
    this.$stickyTopElement = $(element);
  }

  onStickyBottomRef(element) {
    this.$stickyBottomElement = $(element);
  }

  setupWaypoints() {
    if (window.$.prototype.waypoint !== undefined) {
      this.$stickyTopElement.waypoint({
        handler: (direction) => {
          this.$mapContainerElement.toggleClass('stuck-top', direction === 'down');
        },
      });

      $('.stickyBottomLimit').waypoint({
        handler: (direction) => {
          this.$mapContainerElement.toggleClass('stuck-bottom', direction === 'down');
        },
        offset: () => this.$mapContainerElement.outerHeight(),
      });
    }
  }

  handleMapBoundsChange(minLat, maxLat, minLon, maxLon) {
    this.setState({
      minLat, maxLat, minLon, maxLon,
    });
  }

  render() {
    const { events, isArchive } = this.props;
    const {
      minLat, maxLat, minLon, maxLon,
    } = this.state;

    const filteredEvents = events.filter(event => (
      event.lat >= minLat
      && event.lat <= maxLat
      && event.lon >= minLon
      && event.lon <= maxLon
    ));

    const ListClass = isArchive ? EventList : SegmentedEventList;

    return (
      <div className="section section-filtered-event-list" ref={this.onRef}>
        <div
          ref={this.onMapContainerRef}
          className="container container-map"
        >
          <div className="row">
            <div className="col-md-6 offset-md-6">
              <MapFilter events={events} onBoundsChanged={this.handleMapBoundsChange} />
            </div>
          </div>
        </div>
        <div className="stickyTopLimit" ref={this.onStickyTopRef} />
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              { filteredEvents.length > 0
                && <ListClass events={filteredEvents} isArchive={isArchive} />
              }
              {
                filteredEvents.length === 0 && (
                  <div className="message-list-empty">
                    There are no
                    { isArchive ? ' archived ' : ' upcoming ' }
                    { events.length === 0 ? 'events in our database' : 'events in this area' }
                    { !isArchive && events.length === 0 ? ' at this time. Check back soon!' : '.'}
                  </div>
                )
              }
            </div>
          </div>
        </div>
        <div className="stickyBottomLimit" ref={this.onStickyBottomRef} />
      </div>
    );
  }
}

FilteredEventList.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape(EventBasicProperties)).isRequired,
  isArchive: PropTypes.bool,
};

FilteredEventList.defaultProps = {
  isArchive: false,
};
