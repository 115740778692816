import React from 'react';
import { Route, Redirect, Switch, BrowserRouter as Router } from 'react-router-dom';
import Helmet from 'react-helmet';
import { Provider } from 'react-redux';
import { ConfigType, withConfig } from './containers/config';
import Header from './components/header';
import Footer from './components/footer';
import Home from './components/home';
import configureStore from './configure-store';
import StaticPage from './components/static-page';
import SubmissionPage from './components/submission-page';
import ArchivePage from './components/archive-page';

const store = configureStore();

function App(props) {
  const { config } = props;
  const { startYears } = config;

  return (
    <Router>
      <Helmet>
        <title>Pop Math | Math Outreach Events in Europe</title>
        <meta property="og:title" content="Pop Math | Math Outreach Events in Europe" />
        <meta name="description" content="Calendar of mathematics outreach conferences, exhibitions, talks, festivals and other events in Europe." />
        <meta property="og:description" content="Calendar of mathematics outreach conferences, exhibitions, talks, festivals and other events in Europe." />
        <meta property="og:image" content="" />
      </Helmet>
      <Header />
      <main>
        <Switch>
          <Route path="/about">
            <StaticPage url="pages/about.html" title="About Pop Math" />
          </Route>
          <Route path="/privacy-policy">
            <StaticPage url="pages/privacy-policy.html" title="Privacy Policy" />
          </Route>
          <Route path="/impressum">
            <StaticPage url="pages/impressum.html" title="Impressum" />
          </Route>
          <Route path="/submit-event">
            <SubmissionPage />
          </Route>
          <Route path="/archive/:year">
            <Provider store={store}>
              <ArchivePage />
            </Provider>
          </Route>
          <Route path="/archive">
            <Redirect to={`/archive/${startYears.max}`} />
          </Route>
          <Route path={['/', '/event']}>
            <Provider store={store}>
              <Home />
            </Provider>
          </Route>
        </Switch>
      </main>
      <Footer />
    </Router>
  );
}

export default withConfig(App);

App.propTypes = {
  config: ConfigType.isRequired,
};
