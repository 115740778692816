import React from 'react';
import PropTypes from 'prop-types';
import { withConfig, ConfigType } from '../containers/config';
import { formatISODateRangeLong } from '../helpers/date-formatter';
import GoogleMapEmbed from './google-map-embed';
import EventList from './event-list';
import { EventFullProperties, eventLocation } from '../helpers/events';

function EventDetailView({ config, event }) {

  let location = eventLocation(config, event);

  if (event.isOnline) {
    location = `ONLINE / ${location}`;
  }

  return (
    <div className="event-view-detail">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-8">
            <div className="event-view-detail-header">
              <div className="event-type">{config.types[event.type] || config.types[3]}</div>
              <h2 className="event-title">{event.title}</h2>
              {
                event.cancelled
                && <div className="badge badge-danger">This event has been cancelled.</div>
              }
              <div className="info-field event-venue">
                <i className="icon fas fa-map-marker-alt" />
                { location }
              </div>
              { event.start
              && (
                <div className="info-field event-date-range">
                  <i className="icon fas fa-calendar-alt" />
                  { formatISODateRangeLong(event.start, event.end) }
                </div>
              )
              }
              { event.times && (
                <div className="info-field event-times">
                  <i className="icon fas fa-clock" />
                  { event.times }
                </div>
              )}
            </div>
            <div className="event-description" dangerouslySetInnerHTML={{ __html: event.description }} />
            { event.website && (
              <div className="info-field event-site">
                <i className="icon fas fa-external-link-alt" />
                <a href={event.website}>Official website</a>
              </div>
            )}
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="event-venue-map">
              <GoogleMapEmbed lat={event.lat} lon={event.lon} />
            </div>
            <div className="event-venue-details">
              <div className="event-venue">{ event.venue }</div>
              <div className="event-address">{ event.street }</div>
              <div className="event-zip">{ event.zip }</div>
              <div className="event-location">
                {[event.location, config.countries[event.country] || event.country]
                  .filter(s => s).join(', ')}
              </div>
            </div>
          </div>
        </div>
        { (event.nearby && event.nearby.length > 0) && (
          <div className="row mt-5">
            <div className="col-md-12">
              <h3>Nearby events</h3>
              <EventList events={event.nearby} gridMode />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

EventDetailView.propTypes = {
  config: ConfigType.isRequired,
  event: PropTypes.shape(EventFullProperties).isRequired,
};

export default withConfig(EventDetailView);
