import React from 'react';
import { buildURL } from '../helpers/build-url';

export default function GoogleMapEmbed({lat, lon}) {
  const query = {
    q: `${lat}, ${lon}`,
    key: process.env.GOOGLE_MAPS_API_KEY,
  };
  return (
    <div className="google-map-embed">
      <iframe
        title={`Map of ${lat}, ${lon}`}
        width="600"
        height="450"
        frameBorder="0"
        style={{ border: 0 }}
        src={buildURL('https://www.google.com/maps/embed/v1/place', query)}
        allowFullScreen
      />
    </div>
  );
}
