import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FilteredEventList from './filtered-event-list';
import { ConfigType, withConfig } from '../containers/config';
import { fetchEventsIfNeeded } from '../actions';
import Helmet from 'react-helmet';

function ArchivePage(props) {
  const { isFetchingEvents, events, config } = props;
  const { year } = useParams();
  const { startYears } = config;

  useEffect(() => {
    const { dispatch } = props;
    dispatch(fetchEventsIfNeeded(year));
  }, [year]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const navYears = [];
  if (startYears.min && startYears.max) {
    for (let i = Number(startYears.max); i && (i >= Number(startYears.min)); i -= 1) {
      navYears.push(i);
    }
  }

  const title = `${year} Event Archive`;
  const description = `Our list of math outreach events in Europe from ${year}.`;

  return (
    <div>
      <Helmet>
        <title>{`${title} | Pop Math`}</title>
        <meta property="og:title" content={title} />
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col">
            <h1>Event archive</h1>
            <ul className="nav nav-pills nav-archive-year">
              { navYears.map(navYear => (
                <li className="nav-item" key={navYear}>
                  <Link to={`/archive/${navYear}`} className={classNames('nav-link', { active: year == navYear })}>{navYear}</Link>
                </li>
              )) }
            </ul>
          </div>
        </div>
      </div>
      <div className={classNames('event-area', { 'event-area-fetching': isFetchingEvents })} >
        { isFetchingEvents && <div className="loader"><div className="lds-dual-ring" /></div>}
        <FilteredEventList events={events} isArchive />
      </div>
    </div>
  );
}

ArchivePage.propTypes = {
  config: ConfigType.isRequired,
  dispatch: PropTypes.func.isRequired,
  isFetchingEvents: PropTypes.bool,
  events: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = state => ({
  isFetchingEvents: state.events.isFetching,
  events: state.events.items,
});

export default withConfig(connect(mapStateToProps)(ArchivePage));
