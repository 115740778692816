import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { withConfig, ConfigType } from '../containers/config';
import Overlay from './overlay';
import EventDetailView from './event-detail-view';
import { fetchEvent } from '../actions';
import { EventFullProperties, eventMetaDescription } from '../helpers/events';

class OverlaidEventDetailView extends React.Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    const { dispatch, match } = this.props;

    dispatch(fetchEvent(match.params.eventID));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { match } = this.props;
    if (match.params.eventID !== prevProps.match.params.eventID) {
      const { dispatch } = this.props;
      dispatch(fetchEvent(match.params.eventID));
    }
  }

  handleClose() {
    const { history } = this.props;
    if (history.location.state && history.location.state.throughLink === true) {
      history.goBack();
    } else {
      history.push('/');
    }
  }

  render() {
    const { config, event, isFetching } = this.props;
    return (
      <Overlay onClose={this.handleClose}>
        { isFetching && <div className="loader loader-overlay"><div className="lds-dual-ring" /></div> }
        {event !== null && (
          <React.Fragment>
            <Helmet>
              <title>{`${event.title} | Pop Math`}</title>
              <meta property="og:title" content={`${event.title} | Pop Math`} />
              <meta name="description" content={eventMetaDescription(config, event)} />
              <meta property="og:description" content={eventMetaDescription(config, event)} />
            </Helmet>
            <EventDetailView event={event} />
          </React.Fragment>
        ) }
      </Overlay>
    );
  }
}

OverlaidEventDetailView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  event: PropTypes.shape(EventFullProperties),
};

OverlaidEventDetailView.defaultProps = {
  event: null,
};

const mapStateToProps = state => ({
  config: ConfigType.isRequired,
  isFetching: state.eventDetails.isFetching,
  event: state.eventDetails.event,
});

export default connect(mapStateToProps)(withConfig(OverlaidEventDetailView));
