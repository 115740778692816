import React from 'react';
import PropTypes from 'prop-types';
import EventBigTeaser from './event-big-teaser';
import { EventBasicProperties } from '../helpers/events';

export default function EventGrid({ events }) {
  return (
    <div className="event-grid">
      <div className="container">
        <div className="row">
          {events.map(event => (
            <div key={event.id} className="col-md-6 col-lg-4 event-grid-item">
              <EventBigTeaser event={event} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

EventGrid.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape(EventBasicProperties)).isRequired,
};
