import PropTypes from 'prop-types';
import { formatISODateRangeLong } from './date-formatter';

export const EventBasicProperties = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  location: PropTypes.string,
  country: PropTypes.string.isRequired,
  lat: PropTypes.number.isRequired,
  lon: PropTypes.number.isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string,
  type: PropTypes.number.isRequired,
  priority: PropTypes.number.isRequired,
  daysToGo: PropTypes.number.isRequired,
  cancelled: PropTypes.bool,
  online: PropTypes.bool,
  slug: PropTypes.string.isRequired,
};

export const EventFullProperties = Object.assign({}, EventBasicProperties, {
  description: PropTypes.string.isRequired,
  venue: PropTypes.string,
  street: PropTypes.string,
  zip: PropTypes.string,
  times: PropTypes.string,
  website: PropTypes.string,
});

export function eventPath(event) {
  return `/event/${event.id}/${event.slug}`;
}

export function eventLocation(config, event) {
  return [
    event.venue ? `${event.venue} @ ` : null,
    event.location,
    config.countries[event.country] || event.country
  ].filter(s => s).join(', ');
}

export function eventMetaDescription(config, event) {
  const type = config.types[event.type].toLocaleLowerCase();
  const place = eventLocation(config, event);
  const time = formatISODateRangeLong(event.start, event.end);

  const timeConn = (!event.end || (event.start === event.end)) ? 'on' : 'from';

  return `Find information about the ${type} in ${place} ${timeConn} ${time}.`;
}
