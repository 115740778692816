import '@babel/polyfill';
import ReactDom from 'react-dom';
import React from 'react';
import fetch from 'cross-fetch';
import { ConfigContext } from './lib/containers/config';
import Root from './lib/containers/root';
import FatalError from './lib/components/fatal-error';

const rootElement = document.getElementById('root');

fetch(`${process.env.POPMATH_API_ROOT}/config`)
  .then(response => response.json())
  .then((config) => {
    ReactDom.render(
      <ConfigContext.Provider value={config}>
        <Root />
      </ConfigContext.Provider>,
      rootElement
    );
  })
  .catch((err) => {
    ReactDom.render(
      <FatalError
        error={err}
        message="We're unable to connect to our server."
        messageSecondary="Check your internet connection or try again in a few minutes."
      />,
      rootElement
    );
    console.error(err);
  });
