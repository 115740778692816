import React from 'react';
import PropTypes from 'prop-types';
import { Marker } from 'react-google-maps';

const EventMarker = (props) => {
  const handleClick = () => {
    if (props.onClick) {
      props.onClick(props.eventID, props.eventURL);
    }
  };
  return (
    <Marker
      {...props}
      onClick={handleClick}
    />
  );
};

EventMarker.propTypes = {
  eventID: PropTypes.number.isRequired,
  eventURL: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

EventMarker.defaultProps = {
  onClick: () => {},
};

export default EventMarker;
