import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import EventTeaser from './event-teaser';
import { EventBasicProperties } from '../helpers/events';

export default function EventList({ events, gridMode, isArchive }) {
  return (
    <ul className={classNames({ 'event-list': true, 'event-list-grid': gridMode })}>
      {events.map(item => (
        <li key={item.id}>
          <EventTeaser event={item} isArchive={isArchive} />
        </li>
      ))}
    </ul>);
}

EventList.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape(EventBasicProperties)).isRequired,
  gridMode: PropTypes.bool,
  isArchive: PropTypes.bool,
};

EventList.defaultProps = {
  gridMode: false,
  isArchive: false,
};
