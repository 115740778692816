import React from 'react';
import { Link } from 'react-router-dom';

export default function Header() {
  return (
    <header className="header">
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container">
          <Link to="/" className="navbar-brand">
            <img src="/assets/img/popmath-logo.svg" alt="Pop Math. Math outreach events in Europe." />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="fas fa-bars"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item" data-toggle="collapse" data-target="#navbarSupportedContent">
                <Link to="/about" className="nav-link">About</Link>
              </li>
              <li className="nav-item" data-toggle="collapse" data-target="#navbarSupportedContent">
                <Link to="/archive" className="nav-link">Event archive</Link>
              </li>
              <li className="nav-item" data-toggle="collapse" data-target="#navbarSupportedContent">
                <Link to="/submit-event" className="nav-link">Submit an event</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}
