import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function GeolocationButton(props) {
  const { children, onGeolocation, className } = props;
  const [busy, setBusy] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const buttonRef = useRef();
  useEffect(() => {
    if (errorMessage) {
      $(buttonRef.current).popover({
        content: `<span class='text-danger'>${errorMessage}</span>`,
        html: true,
      });
      $(buttonRef.current).popover('show');
    } else {
      $(buttonRef.current).popover('hide');
    }
  }, [errorMessage]);

  const clickHandler = () => {
    if (!busy) {
      setBusy(true);
      setErrorMessage(null);
      navigator.geolocation.getCurrentPosition((position) => {
        setBusy(false);
        onGeolocation(position.coords.latitude, position.coords.longitude);
      }, (error) => {
        switch (error.code) {
          case error.PERMISSION_DENIED:
            setErrorMessage('You must allow access to your location in the browser to see events near you.');
            break;
          case error.POSITION_UNAVAILABLE:
            setErrorMessage('Your current location could not be determined.');
            break;
          case error.TIMEOUT:
            // Time out retrieving position
            setErrorMessage('There was a timeout attempting to retrieve your location.');
            break;
          default:
            setErrorMessage(error.message);
            break;
        }
        setBusy(false);
      });
    }
  };

  return (
    <button
      type="button"
      disabled={busy}
      className={classNames(className, { disabled: busy, loading: busy })}
      onClick={clickHandler}
      ref={buttonRef}
    >
      { children }
      { busy && <div className="loader"><div className="lds-dual-ring" /></div> }
    </button>
  );
}

GeolocationButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onGeolocation: PropTypes.func.isRequired,
};

GeolocationButton.defaultProps = {
  className: '',
};
