import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

export default function Overlay(props) {
  const [show, setShow] = useState(false);
  const { onClose, children } = props;

  useEffect(() => {
    $('body').addClass('with-overlay');
    setShow(true);

    return () => {
      $('body').removeClass('with-overlay');
    };
  });

  const preventClose = (ev) => {
    ev.stopPropagation();
  };

  return (
    <CSSTransition
      in={show}
      classNames="fade"
      timeout={150}
    >
      <div className="overlay" onClick={onClose}>
        <div className="overlay-content" onClick={preventClose}>
          <button className="overlay-close" type="button" onClick={onClose}>
            <span className="fas fa-times" />
          </button>
          {children}
        </div>
      </div>
    </CSSTransition>
  );
}

Overlay.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
};

Overlay.defaultProps = {
  onClose: () => {},
};
