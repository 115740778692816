import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import EventGrid from './event-grid';
import FilteredEventList from './filtered-event-list';
import OverlaidEventDetailView from './overlaid-event-detail-view';
import { ConfigType, withConfig } from '../containers/config';
import { fetchEventsIfNeeded, fetchFeaturedEvents } from '../actions';

function Home(props) {
  if (window.Waypoint !== undefined) window.Waypoint.refreshAll();
  const {
    isFetchingFeatured, isFetchingEvents, events, featured,
  } = props;

  useEffect(() => {
    const { config, dispatch } = props;
    dispatch(fetchFeaturedEvents(
      config.location ? config.location.lat : null,
      config.location ? config.location.lon : null
    ));
    dispatch(fetchEventsIfNeeded());
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <main>
      { isFetchingFeatured && <div className="loader"><div className="lds-dual-ring" /></div>}
      {featured.length !== 0 && (
        <div className="section section-featured section-featured-global">
          <EventGrid events={featured} />
        </div>
      )}
      { isFetchingEvents && <div className="loader"><div className="lds-dual-ring" /></div>}
      { !isFetchingEvents && <FilteredEventList events={events} /> }
      <Route path="/event/:eventID/:eventSlug" component={OverlaidEventDetailView} />
    </main>
  );
}

Home.propTypes = {
  config: ConfigType.isRequired,
  dispatch: PropTypes.func.isRequired,
  isFetchingFeatured: PropTypes.bool,
  isFetchingEvents: PropTypes.bool,
  events: PropTypes.arrayOf(PropTypes.object),
  featured: PropTypes.arrayOf(PropTypes.object),
};

Home.defaultProps = {
  isFetchingFeatured: false,
  isFetchingEvents: false,
  events: [],
  featured: [],
};

const mapStateToProps = state => ({
  isFetchingEvents: state.events.isFetching,
  events: state.events.items,
  isFetchingFeatured: state.featuredEvents.isFetching,
  featured: state.featuredEvents.items,
});

export default withConfig(connect(mapStateToProps)(Home));
