import React from 'react';
import PropTypes from 'prop-types';

export const ConfigContext = React.createContext({
  types: {},
  countries: {},
  startYears: {
    min: null,
    max: null,
  },
});

export function withConfig(Component) {
  return function ConfigComponent(props) {
    return (
      <ConfigContext.Consumer>
        { config => <Component {...props} config={config} /> }
      </ConfigContext.Consumer>
    );
  };
}

export const ConfigType = PropTypes.shape({
  types: PropTypes.objectOf(PropTypes.string).isRequired,
  countries: PropTypes.objectOf(PropTypes.string).isRequired,
  startYears: PropTypes.shape({
    min: PropTypes.string,
    max: PropTypes.string,
  }),
});
