import { combineReducers } from 'redux';
import {
  REQUEST_FEATURED,
  RECEIVE_FEATURED,
  CLEAR_EVENT_DETAILS,
  REQUEST_EVENT_DETAILS,
  RECEIVE_EVENT_DETAILS,
  RECEIVE_EVENTS,
  REQUEST_EVENTS,
} from './actions';

const defaultFeaturedEvents = {
  isFetching: false,
  featured: [],
};

function featuredEvents(state = defaultFeaturedEvents, action) {
  switch (action.type) {
    case REQUEST_FEATURED:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case RECEIVE_FEATURED:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.featured,
      });
    default:
      return state;
  }
}


const defaultEvents = {
  isFetching: false,
  items: [],
};

function events(state = defaultEvents, action) {
  switch (action.type) {
    case REQUEST_EVENTS:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case RECEIVE_EVENTS:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.events,
      });
    default:
      return state;
  }
}

const defaultEventDetails = {
  event: null,
  isFetching: false,
};

function eventDetails(state = defaultEventDetails, action) {
  switch (action.type) {
    case REQUEST_EVENT_DETAILS:
      return Object.assign({}, state, {
        event: null,
        isFetching: true,
      });
    case RECEIVE_EVENT_DETAILS:
      return Object.assign({}, state, {
        event: action.event,
        isFetching: false,
      });
    case CLEAR_EVENT_DETAILS:
      return Object.assign({}, state, {
        event: null,
        isFetching: false,
      });
    default:
      return state;
  }
}

const rootReducer = combineReducers({
  featuredEvents,
  events,
  eventDetails,
});

export default rootReducer;
