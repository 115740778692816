import React from 'react';
import PropTypes from 'prop-types';

export default function FatalError(props) {
  const { error, message, messageSecondary } = props;

  return (
    <div className="fatal-error">
      <div className="fatal-error-main">
        <span className="fas fa-exclamation-triangle" />
        { message || 'Something bad has happened.' }
      </div>
      <div className="fatal-error-secondary">
        { messageSecondary || 'Please try again in a few minutes.' }
      </div>
      { error && (
        <div className="fatal-error-debug">
          { error.message }
        </div>
      )}
    </div>
  );
}

FatalError.propTypes = {
  error: PropTypes.instanceOf(Error),
  message: PropTypes.string,
  messageSecondary: PropTypes.string,
};

FatalError.defaultProps = {
  error: null,
  message: null,
  messageSecondary: null,
};
