import React, { useRef } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import { ConfigType, withConfig } from '../containers/config';
import {
  PMTextInput, PMTextarea, PMCheckbox, PMSelect, PMDatePicker, PMHCaptcha,
} from './form-fields';
import { toISODateString } from '../helpers/date-formatter';
import { postEvent } from '../api';

const HCaptchaSiteKey = process.env.HCAPTCHA_SITE_KEY;

function submitEvent(values) {
  const conversions = {
    event_type: values.event_type ? Number(values.event_type) : null,
    start: values.start ? toISODateString(values.start) : null,
    end: values.end ? toISODateString(values.end) : null,
  };
  // Filter conversions with null value and copy the rest to a new object
  const data = Object.assign({}, values,
    Object.entries(conversions)
      .reduce((a, [k, v]) => (v == null ? a : (a[k]=v, a)), {}));

  return postEvent(data)
    .then((response) => {
      if (response.status === 500 || response.status === 403) {
        return response.json()
          .then(({ errors }) => {
            throw new Error(errors.join('\n'));
          });
      }
      return response.json();
    });
}

const SubmissionForm = (props) => {
  const { config } = props;
  const captchaRef = useRef(null);

  window.myCaptcha = captchaRef;

  return (
    <Formik
      initialStatus={{
        success: null,
        serverMessage: null,
      }}
      initialValues={{
        title: '',
        description: '',
        event_type: '',
        venue: '',
        is_online: false,
        country: '',
        region: '',
        city: '',
        street: '',
        zip: '',
        start: '',
        end: '',
        times: '',
        website: '',
        submitter_name: '',
        submitter_email: '',
        submitter_organization: '',
      }}
      validationSchema={Yup.object({
        title: Yup.string()
          .min(5, 'The name of the event must have at least 5 characters')
          .max(255, "The name of the event can't have more than 255 characters")
          .required('Required'),
        description: Yup.string()
          .max(5000, "The event description can't be longer than 5000 characters'")
          .required('Required'),
        event_type: Yup.string()
          .required('Required'),
        venue: Yup.string()
          .max(100, "The venue name can't be longer than 100 characters"),
        is_online: Yup.boolean(),
        country: Yup.string()
          .required('Required'),
        region: Yup.string()
          .max(50, "The name of the region can't be longer than 50 characters"),
        city: Yup.string()
          .max(50, "The name of the city can't be longer than 50 characters")
          .required('Required'),
        street: Yup.string()
          .max(1000, "The street address can't be longer than 1000 characters"),
        zip: Yup.string()
          .max(30, "The postal code can't be longer than 30 characters"),
        start: Yup.date()
          .required('Required'),
        end: Yup.date().min(Yup.ref('start'), 'End date should be after the start date.'),
        times: Yup.string()
          .max(1000, "The opening times can't be longer than 1000 characters")
          .required('Required'),
        website: Yup.string()
          .url('Must be a valid URL, including the initial http:// or https://')
          .max(255, "The URL can't be longer than 255 characters"),
        submitter_name: Yup.string()
          .max(100, 'Maximum 100 characters')
          .required('Required'),
        submitter_email: Yup.string()
          .email('Must be a valid email address')
          .max(100, 'Maximum 100 characters')
          .required('Required'),
        submitter_organization: Yup.string()
          .max(100, 'Maximum 100 characters'),
        captcha: Yup.string()
          .required('You must do the captcha verification.'),
      })}
      onSubmit={(values, { setStatus, resetForm }) => {
        setStatus({ success: null, serverMessage: null });
        return submitEvent(values)
          .then(() => {
            captchaRef.current.current.resetCaptcha();
            resetForm({ status: { success: true, serverMessage: null } });
          })
          .catch((error) => {
            setStatus({ success: false, serverMessage: error.message });
          });
      }}
    >
      {({ isSubmitting, isValidating, isValid, status, submitCount }) => (
        <Form className={classNames('form-styled', { submitting: isSubmitting })}>
          <p className="mb-4">
            <span className="required-asterisk">*</span>
            { ' = required fields' }
          </p>
          <PMTextInput
            label="Event name"
            name="title"
            pmRequired
            disabled={isSubmitting && !isValidating}
          />
          <PMTextarea
            label="Description"
            name="description"
            pmRequired
            disabled={isSubmitting && !isValidating}
          />
          <PMSelect
            label="Type of event"
            name="event_type"
            pmRequired
            disabled={isSubmitting && !isValidating}
          >
            <option value="" />
            {Object.entries(config.types)
              .map(([key, value]) => (
                <option key={`event_type-${key}`} value={key}>{value}</option>
              ))}
          </PMSelect>
          <PMTextInput
            label="Official event website"
            name="website"
            disabled={isSubmitting && !isValidating}
          />
          <h2>Place</h2>
          <PMCheckbox
            label="Online event"
            name="is_online"
            description="Check to indicate this event can be attended online.<br>If there's no associated physical venue enter the country / city / address of the organizers."
            disabled={isSubmitting && !isValidating}
          />
          <PMTextInput
            label="Name of the venue"
            name="venue"
            disabled={isSubmitting && !isValidating}
          />
          <PMSelect
            label="Country"
            name="country"
            pmRequired
            disabled={isSubmitting && !isValidating}
          >
            <option value="" />
            {Object.entries(config.countries)
              .sort(([, v1], [, v2]) => {
                if (v1 < v2) return -1;
                if (v1 > v2) return 1;
                return 0;
              })
              .map(([key, value]) => (
                <option key={`countries-${key}`} value={key}>{value}</option>
              ))}
          </PMSelect>
          <div className="row">
            <div className="col-md-6">
              <PMTextInput
                label="City"
                name="city"
                pmRequired
                disabled={isSubmitting && !isValidating}
              />
            </div>
            <div className="col-md-6">
              <PMTextInput
                label="Region / State / Province"
                name="region"
                disabled={isSubmitting && !isValidating}
              />
            </div>
          </div>
          <PMTextarea
            label="Street address"
            name="street"
            disabled={isSubmitting && !isValidating}
          />
          <PMTextInput
            label="Postal code"
            name="zip"
            disabled={isSubmitting && !isValidating}
          />
          <h2>Time and date</h2>
          <div className="row">
            <div className="col-md-6">
              <PMDatePicker
                label="Start date"
                name="start"
                pmRequired
                disabled={isSubmitting && !isValidating}
              />
            </div>
            <div className="col-md-6">
              <PMDatePicker
                label="End date"
                name="end"
                description="Leave blank if the event ends the same day it starts."
                disabled={isSubmitting && !isValidating}
              />
            </div>
          </div>
          <PMTextarea
            label="Opening times"
            name="times"
            pmRequired
            disabled={isSubmitting && !isValidating}
          />
          <h2>About you</h2>
          <p>
            The following information will not be published on our site. We require it
            exclusively for administrative purposes.
          </p>
          <PMTextInput
            label="Your name"
            name="submitter_name"
            pmRequired
            disabled={isSubmitting && !isValidating}
          />
          <PMTextInput
            label="Your email address"
            name="submitter_email"
            description="We will only use your email address for direct contact in relation to your event if needed (e.g., to request extra information, clarification or validation purposes).<br />It will NOT be published or shared with third parties."
            pmRequired
            disabled={isSubmitting && !isValidating}
          />
          <PMTextInput
            label="Organization / Institution / Company"
            name="submitter_organization"
            disabled={isSubmitting && !isValidating}
          />
          <PMHCaptcha
            name="captcha"
            sitekey={HCaptchaSiteKey}
            ref={captchaRef}
          />
          <div className="actions mt-5">
            { !isSubmitting && status.success === true && (
              <div className="alert alert-success fade show">
                Your event was submitted successfully.
                <br />
                If it is accepted, it might take 2-3 working days to appear in our calendar.
              </div>
            )}
            { !isSubmitting && status.success === false && (
              <div className="alert alert-warning">
                There was an error submitting your event:
                <br />
                { status.serverMessage }
              </div>
            )}
            <button
              className="btn btn-primary btn-lg btn-submit mb-2 mr-2"
              type="submit"
              disabled={isSubmitting && !isValidating}
            >
              { (isSubmitting && !isValidating) ? (
                <React.Fragment>
                  Submitting
                  <div className="loader"><div className="lds-dual-ring" /></div>
                </React.Fragment>
              ) : 'Submit'}
            </button>
            { !isValid && (submitCount >= 1) && <span className="text-danger d-inline-block align-middle">Fix the errors marked above to submit the form.</span> }
          </div>
        </Form>
      )}
    </Formik>
  );
};

SubmissionForm.propTypes = {
  config: ConfigType.isRequired,
};

export default withConfig(SubmissionForm);
