import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-12 col-md-5 col-lg-5 py-4">
              <strong>Pop Math</strong> is a non-profit project of the <a rel='noopener' href='https://euromathsoc.org/committee-outreach'>Mathematics Outreach and Engagement Committee</a> of the <a href='https://euromathsoc.org/' rel='noopener'>European Mathematical Society</a>.
            </div>
            <div className="col-7 col-md-5 col-lg-4">
              <img className="img-fluid" src="/assets/img/logo-ems.svg" alt="European Mathematical Society." />
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <ul className="sitemap">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/archive">Event Archive</Link></li>
                <li><Link to="/submit-event">Submit an Event</Link></li>
              </ul>
            </div>
            <div className="col-md-3">
              <ul className="sitemap">
                <li><Link to="/about">About</Link></li>
                <li><Link to="/privacy-policy">Privacy policy</Link></li>
                <li><Link to="/impressum">Impressum</Link></li>
              </ul>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col">
              <small>© 2021 European Mathematical Society</small>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
