export const monthNames = [
  'January', 'February', 'March', 'April',
  'May', 'June', 'July', 'August',
  'September', 'October', 'November', 'December',
];

export const shortMonthNames = [
  'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
];

export function parseISODate(isoDate) {
  const parts = isoDate.split('-');
  return {
    year: Number(parts[0]),
    month: Number(parts[1]),
    day: Number(parts[2]),
  };
}

function formatDateLong(date) {
  return `${monthNames[date.month - 1]} ${date.day}, ${date.year}`;
}

function formatDateRangeLong(dateFrom, dateTo) {
  if (dateFrom.day === dateTo.day
    && dateFrom.month === dateTo.month
    && dateFrom.year === dateTo.year) {
    return formatDateLong(dateFrom);
  }
  if (dateFrom.month === dateTo.month
    && dateFrom.year === dateTo.year) {
    return `${monthNames[dateFrom.month - 1]} ${dateFrom.day} to ${dateTo.day}, ${dateFrom.year}`;
  }
  if (dateFrom.year === dateTo.year) {
    return `${monthNames[dateFrom.month - 1]} ${dateFrom.day} to ${monthNames[dateTo.month - 1]} ${dateTo.day}, ${dateFrom.year}`;
  }
  return `${formatDateLong(dateFrom)} to ${formatDateLong(dateTo)}`;
}

export function formatISODateLong(isoDate) {
  return formatDateLong(parseISODate(isoDate));
}

export function formatISODateRangeLong(isoDateFrom, isoDateTo = null) {
  if (isoDateTo !== null) {
    return formatDateRangeLong(parseISODate(isoDateFrom), parseISODate(isoDateTo));
  }
  return formatDateLong(parseISODate(isoDateFrom));
}

/**
 * Converts a Date into yyyy-mm-dd format ignoring time zone information.
 * This is needed because the react-datepicker component doesn't have
 * support for specifying UTC offset.
 */
export function toISODateString(aDate) {
  const pad = str => (str.length === 1 ? `0${str}` : str);

  const month = pad(String(aDate.getMonth() + 1));
  const date = pad(String(aDate.getDate()));

  return `${aDate.getFullYear()}-${month}-${date}`;
}
