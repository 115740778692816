import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withConfig, ConfigType } from '../containers/config';
import { shortMonthNames, parseISODate } from '../helpers/date-formatter';
import { EventBasicProperties, eventPath } from '../helpers/events';

function EventTeaser({ config, event, onClick, isArchive }) {
  const ongoing = !isArchive && event.end && event.daysToGo < 0;
  const date = ongoing ? parseISODate(event.end) : parseISODate(event.start);
  let location = [event.location, config.countries[event.country] || event.country]
    .filter(s => s).join(', ');
  if (event.isOnline) {
    location = `ONLINE / ${location}`;
  }

  return (
    <Link
      className={classNames('event-teaser', {
        'event-teaser-cancelled': event.cancelled,
        'event-teaser-ongoing': ongoing,
        'event-teaser-archive': isArchive,
      })}
      to={{ pathname: eventPath(event), state: { throughLink: true } }}
      onClick={onClick}
    >
      <div className={classNames('teaser-date')}>
        { ongoing && <span className="teaser-date-prefix">Until</span> }
        { isArchive && <span className="teaser-date-prefix">{ date.year }</span> }
        <span className="teaser-date-month">{shortMonthNames[date.month - 1]}</span>
        <span className="teaser-date-day">{date.day}</span>
      </div>
      <div className="teaser-location">{location}</div>
      <div className="teaser-title">{event.title}</div>
      <div className="teaser-type">
        {config.types[event.type] || config.types[3]}
        {event.cancelled && ' '}
        {event.cancelled && <span className="badge badge-danger">Cancelled</span>}
      </div>
    </Link>
  );
}

EventTeaser.propTypes = {
  event: PropTypes.shape(EventBasicProperties).isRequired,
  isArchive: PropTypes.bool,
  onClick: PropTypes.func,
  config: ConfigType.isRequired,
};

EventTeaser.defaultProps = {
  isArchive: false,
  onClick: () => {},
};

export default withConfig(EventTeaser);
