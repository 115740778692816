import fetch from 'cross-fetch';

const apiRoot = process.env.POPMATH_API_ROOT;

export function postEvent(data) {

  const formData = new FormData();
  Object.entries(data).forEach(([k, v]) => {
    if (k === 'captcha') {
      formData.append(k, v);
    } else {
      formData.append(`event[${k}]`, v);
    }
  });

  return fetch(`${apiRoot}/event/create`, {
    method: 'POST',
    body: formData,
  });
}
