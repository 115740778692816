/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import fetch from 'cross-fetch';
import FatalError from './fatal-error';
import { Helmet } from 'react-helmet';

export default function StaticPage(props) {
  const { url, title, description } = props;
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(url)
      .then(response => response.text())
      .then((text) => {
        setContent(text);
      })
      .catch((err) => {
        setError(err);
      })
      .then(() => {
        setLoading(false);
        window.scrollTo({ top: 0 });
      });
  }, [url]);

  return (
    <React.Fragment>
      <Helmet>
        <title>{`${title} | Pop Math`}</title>
        <meta property="og:title" content={`${title} | Pop Math`} />
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
      </Helmet>
      { loading && <div className="loader"><div className="lds-dual-ring" /></div>}
      { !loading && content && <div className="static-page" dangerouslySetInnerHTML={{ __html: content }} />}
      { !loading && error && <FatalError error={error} message="There was an error loading the requested page." messageSecondary="Check your internet connection or try again in a few minutes." />}
    </React.Fragment>
  );
}

StaticPage.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

StaticPage.defaultProps = {
  description: '',
};
